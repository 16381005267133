var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-container",
        { staticClass: "px-lg-5", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "8", sm: "12", cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "date-card py-2 px-lg-2 px-md-2",
                          attrs: { lg: "12", md: "12", sm: "12" }
                        },
                        [
                          _c("offices-card", {
                            attrs: { events: _vm.groupedEvents }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "small-card pa-2",
                          attrs: { lg: "4", md: "4", sm: "12", cols: "12" }
                        },
                        [_c("goals-card")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "small-card py-2 px-lg-2 px-md-2",
                          attrs: { lg: "4", md: "4", sm: "6", cols: "12" }
                        },
                        [
                          _c("list-card", {
                            attrs: {
                              records: _vm.groupedEvents
                                ? _vm.groupedEvents[_vm.calendar.BIRTHDAYS]
                                : [],
                              icon: "mdi-cake-variant",
                              title: _vm.$tc("layout.home.birthdays", 2),
                              emptyMessage: _vm.$t("messages.emptyState", {
                                entity: _vm.$tc("layout.home.birthdays")
                              })
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "small-card py-2 px-lg-2 px-md-2",
                          attrs: { lg: "4", md: "4", sm: "6", cols: "12" }
                        },
                        [
                          _c("list-card", {
                            attrs: {
                              records: _vm.groupedEvents
                                ? _vm.groupedEvents[
                                    _vm.calendar.WORK_ANNIVERSARIES
                                  ]
                                : [],
                              icon: "mdi-calendar-star",
                              title: _vm.$tc("layout.home.anniversaries", 2),
                              emptyMessage: _vm.$t("messages.emptyState", {
                                entity: _vm.$tc("layout.home.anniversaries")
                              })
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "medium-card pa-2",
                          attrs: { md: "6", sm: "6", cols: "12" }
                        },
                        [_c("worklog-card")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "medium-card pa-2",
                          attrs: { md: "6", sm: "6", cols: "12" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "rounded-card home-widget",
                              attrs: { flat: "", outlined: "" }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "py-2 justify-center" },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "primary--text" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            left: "",
                                            color: "primary",
                                            small: ""
                                          }
                                        },
                                        [_vm._v("mdi-calendar")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("layout.home.googleEvents")
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm.groupedEvents &&
                              _vm.groupedEvents[_vm.calendar.GOOGLE_EVENTS]
                                ? [
                                    _c(
                                      "VuePerfectScrollbar",
                                      { staticClass: "scroll-area" },
                                      [
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pt-0" },
                                          [
                                            _c(
                                              "v-timeline",
                                              {
                                                attrs: {
                                                  "align-top": "",
                                                  dense: ""
                                                }
                                              },
                                              _vm._l(
                                                _vm.groupedEvents[
                                                  _vm.calendar.GOOGLE_EVENTS
                                                ],
                                                function(event) {
                                                  return _c(
                                                    "v-timeline-item",
                                                    {
                                                      key: "event_" + event.id,
                                                      attrs: {
                                                        color: "primary",
                                                        small: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0",
                                                              attrs: {
                                                                cols: "12",
                                                                lg: "4"
                                                              }
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .moment(
                                                                          event.start
                                                                        )
                                                                        .format(
                                                                          "hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .moment(
                                                                          event.end
                                                                        )
                                                                        .format(
                                                                          "hh:mm A"
                                                                        )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              staticClass:
                                                                "pt-0"
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._.replace(
                                                                      event.title,
                                                                      /\d*:\d*[p|a][m]/gm,
                                                                      ""
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "caption"
                                                                },
                                                                [
                                                                  _c(
                                                                    "a",
                                                                    {
                                                                      attrs: {
                                                                        href:
                                                                          event
                                                                            .details
                                                                            .link
                                                                      }
                                                                    },
                                                                    [
                                                                      _c("p", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "layout.joinMeeting"
                                                                            )
                                                                          )
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "px-3 text-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("messages.emptyState", {
                                                entity: _vm.$tc(
                                                  "models.calendar.events.entity"
                                                )
                                              })
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "large-card pa-2 mb-md-6",
                  attrs: { md: "4", sm: "12", cols: "12" }
                },
                [
                  _c("leaves-card", {
                    attrs: { records: _vm.requestsPerLeaveType || {} }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }