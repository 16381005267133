var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "home-widget", attrs: { flat: "", outlined: "" } },
    [
      _c(
        "VuePerfectScrollbar",
        { staticClass: "scroll-area row pa-0 ma-0" },
        [
          _c(
            "v-row",
            { staticClass: "pa-0 ma-0" },
            _vm._l(_vm.offices.items, function(office) {
              return _c(
                "v-card-text",
                {
                  key: office.id,
                  staticClass: "col-sm-6 col-12 text-center clock-container"
                },
                [
                  _c("app-clock", {
                    attrs: {
                      timezone: office.timezone,
                      event:
                        _vm.events &&
                        _vm.events[
                          _vm.calendar.OFFICE_HOLIDAYS + "_" + office.id
                        ]
                          ? _vm.events[
                              _vm.calendar.OFFICE_HOLIDAYS + "_" + office.id
                            ][0].title
                          : null
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }