var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "col-12 mt-md-3 rounded-card home-widget",
      attrs: { flat: "", outlined: "" }
    },
    [
      _c("v-card-title", { staticClass: "pt-0 justify-center" }, [
        _c(
          "h3",
          { staticClass: "primary--text" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mb-2",
                attrs: { left: "", color: "primary", small: "" }
              },
              [_vm._v("mdi-beach")]
            ),
            _vm._v(" " + _vm._s(_vm.$t("layout.home.leaves")) + " ")
          ],
          1
        )
      ]),
      !_vm._.isEmpty(_vm.records)
        ? [
            _c(
              "VuePerfectScrollbar",
              { staticClass: "scroll-area" },
              [
                _c(
                  "v-card-text",
                  { staticClass: "pt-0" },
                  _vm._l(_vm._.keys(_vm.records), function(key) {
                    return _c(
                      "v-list",
                      { key: key, attrs: { dense: "", disabled: "" } },
                      [
                        _c("h4", [_vm._v(_vm._s(key))]),
                        _c(
                          "v-list-item-group",
                          { staticClass: "align-items-center" },
                          _vm._l(_vm.records[key], function(request) {
                            return _c(
                              "v-list-item",
                              { key: "request_" + request.id },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._.replace(
                                          request.title,
                                          /\d*:\d*[p|a][m]/gm,
                                          ""
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "span",
                                  { staticClass: "caption" },
                                  [
                                    request.details.unit ===
                                    _vm.userLeaveUnits.HOURS
                                      ? [
                                          _c(
                                            "v-chip",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm
                                                      .moment(request.start)
                                                      .format("hh:mm A")
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm
                                                      .moment(request.end)
                                                      .format("hh:mm A")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "v-chip",
                                            { attrs: { small: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "models.leaveRequest.attributes.units." +
                                                        request.details.unit
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _c("v-divider", { staticClass: "my-2" })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        : [
            _c("div", { staticClass: "px-3 text-center" }, [
              _vm._v(" " + _vm._s(_vm.$t("messages.home.noLeaves")) + " ")
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }