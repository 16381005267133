var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-card home-widget",
      attrs: { flat: "", outlined: "" }
    },
    [
      _c("v-card-title", { staticClass: "py-2 justify-center" }, [
        _c(
          "h3",
          { staticClass: "primary--text" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mb-2",
                attrs: { left: "", color: "primary", small: "" }
              },
              [_vm._v(_vm._s(_vm.icon))]
            ),
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ],
          1
        )
      ]),
      !_vm._.isEmpty(_vm.records)
        ? [
            _c(
              "VuePerfectScrollbar",
              { staticClass: "scroll-area" },
              [
                _c(
                  "v-card-text",
                  { staticClass: "pt-0 px-0 pb-2 fill-height" },
                  [
                    _c(
                      "v-list",
                      { attrs: { dense: "" } },
                      _vm._l(_vm.records, function(record) {
                        return _c(
                          "v-list-item",
                          { key: record.id, staticClass: "my-0 py-0" },
                          [
                            _c(
                              "v-list-item-content",
                              { staticClass: "justify-center py-0" },
                              [_vm._v(" " + _vm._s(record.title) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c("div", { staticClass: "px-3 text-center" }, [
              _vm._v(" " + _vm._s(_vm.emptyMessage) + " ")
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }