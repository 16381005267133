var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-card home-widget",
      attrs: { flat: "", outlined: "" }
    },
    [
      _c("v-card-title", { staticClass: "py-2 justify-center" }, [
        _c(
          "h3",
          { staticClass: "primary--text" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mb-2",
                attrs: { left: "", color: "primary", small: "" }
              },
              [_vm._v("mdi-timer")]
            ),
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("layout.home.worklogSummary", {
                    month: _vm.moment().format("MMMM")
                  })
                ) +
                " "
            )
          ],
          1
        )
      ]),
      _c("v-card-subtitle", { staticClass: "pt-3 text-center" }, [
        _c(
          "h4",
          [
            _c("v-chip", { attrs: { small: "" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("layout.home.totalWorkingDays", {
                      days: _vm.statistics.workingDays,
                      hours: _vm.statistics.workingHours
                    })
                  ) +
                  " "
              )
            ])
          ],
          1
        )
      ]),
      _c(
        "VuePerfectScrollbar",
        { staticClass: "scroll-area" },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0 pb-2" },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th"),
                            _c("th"),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(_vm.statistics.totalWorklogs))
                            ])
                          ])
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.statistics.summary, function(item) {
                            return _c("tr", { key: item.project }, [
                              _c("td", [
                                item.project
                                  ? _c("span", [_vm._v(_vm._s(item.project))])
                                  : _c(
                                      "span",
                                      { staticClass: "text-muted-light" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("views.constants.noProject")
                                          )
                                        )
                                      ]
                                    )
                              ]),
                              _c("td", { staticClass: "text-right" }),
                              _c("td", { staticClass: "text-right" }, [
                                _c("h4", [_vm._v(_vm._s(item.duration))]),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._.round(
                                        (item.durationInSec * 100) /
                                          _vm.statistics.totalWorklogsInSec,
                                        2
                                      )
                                    ) + " %"
                                  )
                                ])
                              ])
                            ])
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }