var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "rounded-card home-widget",
      attrs: { flat: "", outlined: "" }
    },
    [
      _c("v-card-title", { staticClass: "py-2 justify-center" }, [
        _c(
          "h3",
          { staticClass: "primary--text" },
          [
            _c(
              "v-icon",
              {
                staticClass: "mb-2",
                attrs: { left: "", color: "primary", small: "" }
              },
              [_vm._v("mdi-bullseye-arrow")]
            ),
            _vm._v(" " + _vm._s(_vm.$t("layout.home.goals")) + " ")
          ],
          1
        )
      ]),
      _c(
        "VuePerfectScrollbar",
        { staticClass: "scroll-area" },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-0 pb-2" },
            [
              _vm.goals && _vm.goals.count !== 0
                ? _vm._l(_vm.goals.items, function(goal) {
                    return _c(
                      "v-card",
                      {
                        key: "goal_" + goal.id,
                        staticClass: "d-flex align-stretch my-2",
                        attrs: { flat: "" }
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "px-5" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "col-12 text-start pa-0 my-0 subtitle font-weight-bold"
                              },
                              [
                                _vm.progressColor(
                                  goal.progress,
                                  goal.endDate
                                ) === "error"
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                            small: ""
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-alert-circle-outline"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "messages.home.goalDeadline"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(goal.title) + " ")
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "col-7 pa-0 my-0 caption" },
                              [_vm._v(_vm._s(goal.endDate))]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "col-5 text-end pa-0 my-0 caption"
                              },
                              [_vm._v(" " + _vm._s(goal.progress) + "% ")]
                            ),
                            _c("v-progress-linear", {
                              attrs: {
                                rounded: "",
                                height: "10",
                                color: _vm.progressColor(
                                  goal.progress,
                                  goal.endDate
                                ),
                                value: goal.progress
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : [
                    _c("div", { staticClass: "px-3 text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("messages.emptyState", {
                              entity: _vm.$tc("models.goal.entity")
                            })
                          ) +
                          " "
                      )
                    ])
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }