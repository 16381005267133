<template>
  <v-container fluid class="px-lg-5" v-if="!loading">
    <v-row>
      <v-col md="8" sm="12" cols="12">
        <v-row>
          <v-col lg="12" md="12" sm="12" class="date-card py-2 px-lg-2 px-md-2">
            <offices-card :events="groupedEvents"/>
          </v-col>
          <v-col lg="4" md="4" sm="12" cols="12" class="small-card pa-2">
            <goals-card />
          </v-col>
          <v-col lg="4" md="4" sm="6" cols="12" class="small-card py-2 px-lg-2 px-md-2">
            <list-card :records="groupedEvents ? groupedEvents[calendar.BIRTHDAYS] : []"
              icon="mdi-cake-variant" :title="$tc('layout.home.birthdays', 2)"
              :emptyMessage="$t('messages.emptyState', { entity: $tc('layout.home.birthdays') })"/>
          </v-col>
          <v-col lg="4" md="4" sm="6" cols="12" class="small-card py-2 px-lg-2 px-md-2">
            <list-card :records="groupedEvents ? groupedEvents[calendar.WORK_ANNIVERSARIES] : []"
              icon="mdi-calendar-star" :title="$tc('layout.home.anniversaries', 2)"
              :emptyMessage="$t('messages.emptyState', { entity: $tc('layout.home.anniversaries') })"/>
          </v-col>
          <v-col md="6" sm="6" cols="12" class="medium-card pa-2">
            <worklog-card />
          </v-col>
          <v-col md="6" sm="6" cols="12" class="medium-card pa-2">
            <v-card class="rounded-card home-widget" flat outlined>
              <v-card-title class="py-2 justify-center">
                <h3 class="primary--text">
                  <v-icon left class="mb-2" color="primary" small>mdi-calendar</v-icon>
                  {{ $t('layout.home.googleEvents') }}
                </h3>
              </v-card-title>
              <template v-if="groupedEvents && groupedEvents[calendar.GOOGLE_EVENTS]">
                <VuePerfectScrollbar class="scroll-area">
                  <v-card-text class="pt-0">
                      <v-timeline align-top dense>
                        <v-timeline-item
                          v-for="event in groupedEvents[calendar.GOOGLE_EVENTS]" :key="'event_'+event.id"
                          color="primary" small>
                          <v-row>
                            <v-col cols="12" lg="4" class="pt-0">
                              <strong>
                                {{ moment(event.start).format('hh:mm A') }} - {{ moment(event.end).format('hh:mm A') }}
                              </strong>
                            </v-col>
                            <v-col class="pt-0">
                              <strong>{{ _.replace(event.title, /\d*:\d*[p|a][m]/gm, '') }}</strong>
                              <div class="caption">
                                <a :href="event.details.link"><p>{{ $t('layout.joinMeeting') }}</p></a>
                              </div>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                  </v-card-text>
                </VuePerfectScrollbar>
              </template>
              <template v-else>
                <div class="px-3 text-center">
                  {{ $t("messages.emptyState", { entity: $tc("models.calendar.events.entity") }) }}
                </div>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" sm="12" cols="12" class="large-card pa-2 mb-md-6">
        <leaves-card :records="requestsPerLeaveType || {}"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {calendar, goalStatus} from 'constants';
import moment from 'moment';
import GoalsCard from 'components/home/GoalsCard.vue';
import LeavesCard from 'components/home/LeavesCard.vue';
import ListCard from 'components/home/ListCard.vue';
import OfficesCard from 'components/home/OfficesCard.vue';
import WorklogCard from 'components/home/WorklogCard.vue';

export default {
  name: 'Home',
  components: {
    GoalsCard,
    LeavesCard,
    ListCard,
    OfficesCard,
    WorklogCard
  },
  data() {
    return {
      calendar,
      goalStatus,
      moment,
      groupedEvents: null,
      requestsPerLeaveType: null
    };
  },
  computed: mapGetters(['calendarEvents', 'calendars', 'currentUser', 'loading']),
  methods: {
    ...mapActions(['getEvents', 'getCalendars', 'getGoals', 'getOffices', 'getStatistics', 'setLoading'])
  },
  created() {
    const options = {
      page: 1,
      itemsPerPage: 5,
      sortBy: ['endDate'],
      filterBy: {
        status: this.goalStatus.APPROVED,
        assigneeId: this.currentUser.id,
        endDate: this.moment(new Date()).format('YYYY-MM-DD')
      }
    };
    this.setLoading(true);
    Promise.all([
      this.getEvents({
        start: moment.utc(moment(new Date()).startOf('day')).format(),
        end: moment.utc(moment(new Date()).endOf('day')).format()
      }),
      this.getGoals({...options, fields: ['title', 'startDate', 'endDate', 'progress', 'state']}),
      this.getCalendars(),
      this.getOffices({fields: ['timezone']}),
      this.getStatistics()
    ]).then(() => {
      const events = this._.groupBy(this.calendarEvents, (event) => event.groupId);
      const leaves = {};
      this._.forEach(events, (event, key) => {
        if (this._.startsWith(key, 'LEAVES')) {
          leaves[event[0].details.leave.name] = event;
        }
      });
      this.requestsPerLeaveType = leaves;
      this.groupedEvents = events;
      this.setLoading(false);
    });
  }
};
</script>

<style lang="scss">
.home-widget {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.scroll-area {
  position: relative;
  margin: 2px;
  flex-grow: 1;
}
@media only screen and (min-width: 991px) {
  .small-card {
    height: 32vh;
  }
  .date-card {
    height: 14vh;
  }
  .medium-card {
    height: 40vh;
  }
  .large-card {
    height: 86vh;
  }
}
@media only screen and (max-width: 990px) {
  .date-card {
    height: 125px;
  }
  .small-card {
    height: 200px;
  }
  .medium-card {
    height: 300px;
  }
  .large-card {
    min-height: 300px;
    max-height: 400px;
  }
}
</style>
